const defaultLabelOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
} as Intl.DateTimeFormatOptions;

export default function formatDateRange(
  dateRange: string[],
  labelOptions: Intl.DateTimeFormatOptions = {}
): string {
  try {
    const dateTimeFormat = Intl.DateTimeFormat(document.documentElement.lang, {
      ...defaultLabelOptions,
      ...labelOptions,
    });

    return dateTimeFormat.formatRange(
      new Date(new Date(dateRange[0]).toUTCString()),
      new Date(new Date(dateRange[1]).toUTCString())
    );
  } catch (_e) {
    return `${dateRange[0]} - ${dateRange[1]}`;
  }
}
