/* eslint-disable no-unused-vars */
// Imports
import _ from 'lodash';
import * as selectize from '@selectize/selectize';
import * as noUiSlider from 'nouislider';
import * as moment from 'moment';
import Vue from 'vue';
import ClipboardJS from 'clipboard';
import VueGtag from 'vue-gtag';
import Notifications, { notify } from '@kyvg/vue3-notification';
import VueTippy from 'vue-tippy';
import iframeResize from 'iframe-resizer/js/iframeResizer';
import { mixin as VueClickaway } from 'vue3-click-away';
import mitt from 'mitt';
import pluralizeWord from '@/helpers/pluralize-word';
import disableInputAutoCompleteAttrs from '@/helpers/disableInputAutoCompleteAttrs';
import updateTableStickyColumns from '@/helpers/updateTableStickyColumns';
import a11ySkipTo from '@/helpers/a11y-skip-to';
import formatDateRange from '@/helpers/format-date-range';
import truncateText from '@/helpers/truncateText';
import { useTestHelpers } from '@/composables/useTestHelpers';
import sanitizeHTML from 'sanitize-html';
import VueDraggable from 'vuedraggable';
import axios from 'axios';
import { ObserveVisibility } from 'vue-observe-visibility';
import VueApexCharts from 'vue3-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.withXSRFToken = true;
let token = document.head.querySelector('meta[name="csrf-token"]');

axios.interceptors.request.use(
  async function (config) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    if (config.method === 'delete') {
      Object.defineProperty(config, 'data', {
        configurable: true,
        enumerable: true,
        get() {
          return this.body;
        },
        set(val) {
          this.body = val;
        },
      });
    }
    const externalUrls = ['engine.scorm.com'];
    const externalRequest = externalUrls.some((e) => {
      return config.url && config.url.includes(e);
    });

    if (!externalRequest && token) {
      config.headers['X-CSRF-TOKEN'] = token.getAttribute('content');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// TODO - Mapping all the responses so that it matches old vue-resource library
// Possibly not the best choice - ideally we can update the code elsewhere later
axios.interceptors.response.use(
  (response) => {
    response.ok = response.status >= 200 && response.status < 300;
    Object.defineProperty(response, 'body', {
      configurable: true,
      enumerable: true,
      get() {
        return this.data;
      },
      set(val) {
        this.data = val;
      },
    });

    return response;
  },
  (error) => {
    error.response.ok = false;
    Object.defineProperty(error.response, 'body', {
      configurable: true,
      enumerable: true,
      get() {
        return this.data;
      },
      set(val) {
        this.data = val;
      },
    });

    return Promise.reject(error.response);
  }
);

// Legacy helpers are standard methods that replace deprecated Vue filters
// in Shakapacker components.
const { generateTestId } = useTestHelpers();
const legacyHelpers = {
  install: () => {
    const helpers = {
      pluralizeWord,
      disableInputAutoCompleteAttrs,
      updateTableStickyColumns,
      formatDateRange,
      sanitizeHTML,
      generateTestId,
      truncateText,
    };
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
    Vue.prototype.$http = axios;
  },
};

// Globals
window.Selectize = selectize;
window._ = _;
window.noUiSlider = noUiSlider;
window.moment = moment;
window.ClipboardJS = ClipboardJS;
window.VueNotification = { notify };
window.VueClickaway = VueClickaway;
window.emitter = mitt();
window.VueGtag = VueGtag;
// Global Vue plugins
Vue.use(Notifications);
Vue.use(VueTippy, {
  defaultProps: {
    arrow: true,
    placement: 'bottom',
    maxWidth: 220,
    animation: 'fade',
    appendTo: document.body,
    boundary: window,
    allowHTML: true,
  },
});
Vue.use(legacyHelpers);
Vue.use(VueApexCharts);
export default Vue;

VueDraggable.compatConfig = { MODE: 3 };
Vue.component('VueDraggable', VueDraggable);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.directive('resize', {
  beforeMount: function (el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el));
  },
  unmounted: function (el) {
    el.iFrameResizer.removeListeners();
  },
});

Vue.directive('observe-visibility', {
  beforeMount: ObserveVisibility.beforeMount,
  updated: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});
/* eslint-enable no-unused-vars */

document.addEventListener('DOMContentLoaded', () => {
  // Timeout is required to ensure [data-skip-to] buttons render correctly.
  // Although querySelector shows the buttons without setTimeout, event
  // listeners are not being applied unless setTimeout is used.
  setTimeout(() => {
    a11ySkipTo();
  }, 50);
});
