export default function a11ySkipTo(): void {
  const skipToButtons = document.querySelectorAll('[data-skip-to]');
  if (!skipToButtons) return;

  skipToButtons.forEach((button) => {
    if (!(button instanceof HTMLElement)) return;

    const focusTarget = button.dataset['skipTo'];
    if (!focusTarget) return;

    const preventScroll = button.dataset['skipToPreventScroll'] === 'true';
    button.addEventListener('click', () => {
      (document.querySelector(focusTarget) as HTMLElement)?.focus({ preventScroll });
    });
  });
}
