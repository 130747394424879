enum EventType {
  scroll = 'scroll',
  reload = 'reload',
}

export default function updateTableStickyColumns(
  tableWrapper: HTMLElement,
  eventType: EventType
): void {
  if (eventType === EventType.reload) {
    reloadTableStickies(tableWrapper);
  } else {
    repositionStickiesOnScroll(tableWrapper);
  }
}

function repositionStickiesOnScroll(tableWrapper: HTMLElement): void {
  if (tableWrapper.scrollLeft <= 0) return;
  const wrapperLeftOffset = tableWrapper.getBoundingClientRect().left;
  tableWrapper.querySelectorAll<HTMLElement>('.col--sticky').forEach((c) => {
    const leftOffset = c.getBoundingClientRect().left - wrapperLeftOffset;
    c.style.left = `${leftOffset}px`;
  });
}

function reloadTableStickies(tableWrapper: HTMLElement): void {
  tableWrapper.scrollLeft = 0;
  tableWrapper.querySelectorAll<HTMLElement>('.col--sticky').forEach((c) => {
    c.style.left = '';
  });
}
